import * as React from "react";
import {
  useCustomMutation,
  useTranslate,
  useApiUrl,
  useNotification,
} from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import { FormProvider } from "react-hook-form";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { layoutStyles, titleStyles } from "../styles";
import { CustomTitle } from "../../index";
import { useAuthDialog } from "../../../contexts/auth-dialog";

type AuthForgotPasswordForm = {
  email: string;
};

type AuthForgotPasswordProps = {};

export const AuthForgotPassword: React.FC<AuthForgotPasswordProps> = () => {
  const apiUrl = useApiUrl();
  const { closeAuthDialog, openAuthDialog } = useAuthDialog();
  const { open } = useNotification();
  const methods = useForm<AuthForgotPasswordForm>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;
  const { isLoading, mutate } = useCustomMutation();
  const translate = useTranslate();
  const createFeedback = handleSubmit((data) => {
    mutate(
      {
        url: `${apiUrl}/api/auth/forgot-password`,
        method: "post",
        values: data,
      },
      {
        onSuccess: () => {
          closeAuthDialog();
          openAuthDialog("FORGOT_PASSWORD_DIALOG");
          open?.({
            type: "success",
            message: translate("pages.forgotPassword.response.success.message"),
          });
        },
        onError: () => {
          open?.({
            type: "error",
            message: translate("pages.forgotPassword.response.error.message"),
          });
        },
      }
    );
  });
  const PageTitle = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        // marginBottom: "32px",
        fontSize: "20px",
      }}
    >
      <CustomTitle
        collapsed={false}
        wrapperStyles={{
          gap: "8px",
        }}
      />
    </div>
  );

  const Content = (
    <Card elevation={0}>
      <CardContent sx={{ p: "32px", "&:last-child": { pb: "44px" } }}>
        <Typography
          component="h1"
          variant="h5"
          align="center"
          style={titleStyles}
          color="primary"
          fontWeight={700}
        >
          {translate("pages.forgotPassword.title", "Forgot your password?")}
        </Typography>
        <Box component="form" onSubmit={createFeedback}>
          <TextField
            {...register("email", {
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: translate(
                  "pages.forgotPassword.errors.validEmail",
                  "Invalid email address"
                ),
              },
            })}
            id="email"
            margin="normal"
            fullWidth
            label={translate("pages.forgotPassword.fields.email", "Email")}
            error={!!errors.email}
            helperText={<>{errors["email"] ? errors["email"]?.message! : ""}</>}
            name="email"
            autoComplete="email"
            sx={{
              mt: 0,
            }}
          />
          <Button
            type="submit"
            fullWidth
            disableElevation
            variant="contained"
            disabled={isLoading}
            sx={{ mt: "24px" }}
          >
            {translate(
              "pages.forgotPassword.buttons.submit",
              "Send reset instructions"
            )}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <FormProvider {...methods}>
      <Box component="div" style={layoutStyles}>
        <Container
          component="main"
          maxWidth="xs"
          disableGutters
          sx={{
            pt: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {PageTitle}
            {Content}
          </Box>
        </Container>
      </Box>
    </FormProvider>
  );
};
