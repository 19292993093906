import React, { useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useLocation, useNavigate } from "react-router-dom";
import { useNotification, useTranslate } from "@refinedev/core";
import { request } from "../../utils/request";
import { Header } from "../../components";

const STRIPE_KEY = import.meta.env.VITE_APP_STRIPE_KEY;
const stripePromise = loadStripe(STRIPE_KEY);
const Checkout = () => {
  const location = useLocation();
  const translate = useTranslate();
  const { open } = useNotification();
  const navigate = useNavigate();
  useEffect(() => {
    if (!location.state) {
      navigate("/plan");
      open?.({
        message: translate("checkout.stripeProductIdNull"),
        type: "error",
      });
      return;
    }
  }, []);
  // 请求创建支付
  const fetchClientSecret = async () => {
    const {
      state: { stripe_product_id },
    } = location;
    if (!stripe_product_id) return;
    const res = await request({
      url: "/api/stripe/checkout",
      method: "POST",
      data: { stripe_product_id },
    });
    if (res.status !== 200) {
      open?.({
        message:
          res?.data.message || translate("checkout.response.error.description"),
        type: "error",
      });
      navigate(-1);
    }
    // 如果message存在说明已经订阅
    if (res.data.message) {
      navigate("/plan");
      open?.({
        message: res.data.message,
        type: "success",
      });
      return;
    }
    return res.data.client_secret;
  };
  const options = { fetchClientSecret };
  return (
    <div className="bg-white min-h-full">
      <Header />
      <div id="checkout" className="pb-4 px-4 md:p-4">
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>
    </div>
  );
};

export default Checkout;
