import * as React from "react";
import {
  RegisterFormTypes,
  RegisterPageProps,
  useActiveAuthProvider,
  useNotification,
} from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { FormProvider } from "react-hook-form";
import Checkbox from "@mui/material/Checkbox";
import MuiLink from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

import type { BoxProps } from "@mui/material/Box";
import type { CardContentProps } from "@mui/material/CardContent";
import {
  BaseRecord,
  HttpError,
  useTranslate,
  useRouterContext,
  useRouterType,
  useLink,
  useRegister,
} from "@refinedev/core";
import { layoutStyles, titleStyles } from "../styles";
import { FormPropsType } from "../type";
import { CustomTitle } from "../../index";
import { useAuthDialog } from "../../../contexts/auth-dialog";
import { useState } from "react";

type RegisterProps = RegisterPageProps<
  BoxProps,
  CardContentProps,
  FormPropsType
>;

type AuthRegisterFormTypes = RegisterFormTypes & {
  username?: string;
  password2?: string;
};

export const AuthRegister: React.FC<RegisterProps> = (props) => {
  const {
    loginLink,
    wrapperProps,
    contentProps,
    renderContent,
    providers,
    formProps,
    title,
  } = props;
  const { onSubmit, ...useFormProps } = formProps || {};
  const methods = useForm<BaseRecord, HttpError, AuthRegisterFormTypes>({
    ...useFormProps,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = methods;

  // checkbox
  const [checked, setChecked] = useState(true);
  const navigate = useNavigate();
  const authProvider = useActiveAuthProvider();
  const { mutate: registerMutate, isLoading } =
    useRegister<AuthRegisterFormTypes>({
      v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
    });
  const { open } = useNotification();
  const { closeAuthDialog } = useAuthDialog();
  const translate = useTranslate();
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();

  const ActiveLink = routerType === "legacy" ? LegacyLink : Link;

  // 切换checkbox
  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  // 跳转免责声明页面
  const handleToDisclaimer = () => {
    // 关闭弹窗
    closeAuthDialog();
    navigate("/disclaimer");
  };
  const PageTitle =
    title === false ? null : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          // marginBottom: "32px",
          fontSize: "20px",
        }}
      >
        {title ?? (
          <CustomTitle
            collapsed={false}
            wrapperStyles={{
              gap: "8px",
            }}
          />
        )}
      </div>
    );

  const renderProviders = () => {
    if (providers && providers.length > 0) {
      return (
        <>
          <Stack spacing={1}>
            {providers.map((provider: any) => {
              return (
                <Button
                  key={provider.name}
                  color="secondary"
                  fullWidth
                  variant="outlined"
                  sx={{
                    color: "primary.light",
                    borderColor: "primary.light",
                    textTransform: "none",
                  }}
                  onClick={() =>
                    registerMutate({
                      providerName: provider.name,
                    })
                  }
                  startIcon={provider.icon}
                >
                  {provider.label}
                </Button>
              );
            })}
          </Stack>
          <Divider sx={{ fontSize: 12, marginY: "16px" }}>
            {translate("pages.login.divider", "or")}
          </Divider>
        </>
      );
    }
    return null;
  };
  const Content = (
    <Card {...(contentProps ?? {})} elevation={0}>
      <CardContent sx={{ p: "32px", "&:last-child": { pb: "44px" } }}>
        <Typography
          component="h1"
          variant="h5"
          align="center"
          style={titleStyles}
          color="primary"
          fontWeight={700}
        >
          {translate("pages.register.title", "Sign up for your account")}
        </Typography>
        {renderProviders()}
        <Box
          component="form"
          onSubmit={handleSubmit((data) => {
            if (onSubmit) {
              return onSubmit(data);
            }

            return registerMutate(data, {
              onSuccess: (data) => {
                // @ts-ignore
                if (data.success) {
                  open?.({
                    type: "success",
                    message: translate(
                      "pages.register.response.success.message",
                      "The activation link has been sent to your email. Please click the link to verify."
                    ),
                  });
                  closeAuthDialog();
                }
              },
            });
          })}
        >
          <TextField
            {...register("username", {
              required: true,
              pattern: {
                value: /^[\w\u4e00-\u9fa5]{3,}$/i,
                message: translate(
                  "pages.register.errors.validUsername",
                  "Invalid username"
                ),
              },
            })}
            id="username"
            margin="normal"
            fullWidth
            label={translate("pages.register.fields.username", "Username")}
            error={!!errors.username}
            helperText={errors["username"] ? errors["username"].message : ""}
            name="username"
            autoComplete="username"
            sx={{
              my: 0,
            }}
          />
          <TextField
            {...register("email", {
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: translate(
                  "pages.register.errors.validEmail",
                  "Invalid email address"
                ),
              },
            })}
            id="email"
            margin="normal"
            fullWidth
            label={translate("pages.register.fields.email", "Email")}
            error={!!errors.email}
            helperText={errors["email"] ? errors["email"].message : ""}
            name="email"
            autoComplete="email"
            sx={{
              mb: 0,
            }}
          />
          <TextField
            {...register("password", {
              required: true,
              minLength: {
                value: 6,
                message: translate("pages.register.errors.validPassword"),
              },
            })}
            id="password"
            margin="normal"
            fullWidth
            name="password"
            label={translate("pages.register.fields.password", "Password")}
            helperText={errors["password"] ? errors["password"].message : ""}
            error={!!errors.password}
            type="password"
            placeholder="●●●●●●●●"
            autoComplete="password"
            sx={{
              mb: 0,
            }}
          />
          <TextField
            {...register("password2", {
              required: true,
              validate: {
                matchesPreviousPassword: (value) => {
                  const { password } = getValues();
                  return (
                    password === value ||
                    translate("pages.register.errors.validConfirmPassword")
                  );
                },
              },
            })}
            id="password2"
            margin="normal"
            fullWidth
            name="password2"
            label={translate(
              "pages.register.fields.confirmPassword",
              "Confirm Password"
            )}
            helperText={errors["password2"] ? errors["password2"].message : ""}
            error={!!errors.password2}
            type="password"
            placeholder="●●●●●●●●"
            autoComplete="confirm-password"
            sx={{
              mb: 0,
            }}
          />
          <Button
            type="submit"
            fullWidth
            disableElevation
            variant="contained"
            disabled={!checked || isLoading}
            sx={{
              mt: "24px",
            }}
          >
            {translate("pages.register.buttons.submit", "Sign up")}
          </Button>
          <Box sx={{ pt: 1, display: "flex", alignItems: "center" }}>
            <Checkbox
              defaultChecked
              size="small"
              checked={checked}
              onChange={handleChangeCheckbox}
            />
            <span
              className="underline text-xs text-[var(--primary)] cursor-pointer"
              onClick={handleToDisclaimer}
            >
              {translate(
                "pages.register.checkboxLabel",
                "Read and agree Legal disclaimer – Not financial advice"
              )}
            </span>
          </Box>

          {loginLink ?? (
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                mt: "24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="body2" component="span" fontSize="12px">
                {translate(
                  "pages.login.buttons.haveAccount",
                  "Have an account?"
                )}
              </Typography>
              <MuiLink
                ml="4px"
                variant="body2"
                color="primary"
                component={ActiveLink}
                underline="none"
                to="/login"
                fontSize="12px"
                fontWeight="bold"
              >
                {translate("pages.login.signin", "Sign in")}
              </MuiLink>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <FormProvider {...methods}>
      <Box component="div" style={layoutStyles} {...(wrapperProps ?? {})}>
        <Container
          component="main"
          maxWidth="xs"
          disableGutters
          sx={{
            padding: 0,
            pt: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {renderContent ? (
            renderContent(Content, PageTitle)
          ) : (
            <>
              {PageTitle}
              {Content}
            </>
          )}
        </Container>
      </Box>
    </FormProvider>
  );
};
