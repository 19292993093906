import { styled } from "@mui/material/styles";
import { Menu } from "@mui/material";

export const MMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    minWidth: 220,
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color: "var(--primary-2B363E)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
  },
}));
