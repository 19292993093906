import React from "react";
import "./loding.css";

type LoadingProps = { size?: number };
export const Loading: React.FC<LoadingProps> = () => {
  return (
    <div className="dots">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
