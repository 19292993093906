import {request} from "../../../utils/request";
import {LoginParams} from "./type";

/**
 * 登录
 * @param data
 */
export const createLogin = async (data: LoginParams) => {
    return request({
        url: '/api/auth/local',
        method: 'POST',
        data
    })
}
