import React from "react";
import { useAuthDialog } from "../../../contexts/auth-dialog";
import { useTranslate } from "@refinedev/core";

const AuthForgotPasswordDialog = () => {
  const translate = useTranslate();
  const { closeAuthDialog } = useAuthDialog();
  return (
    <div className="p-6">
      <p className="text-center">
        {translate("pages.forgotPassword.response.success.message")}
      </p>
      <div className="pt-10 pb-2 flex justify-center items-center gap-x-6">
        <button
          onClick={closeAuthDialog}
          className="w-28 h-8 bg-[var(--primary-394FF6)] flex justify-center items-center gap-x-2 text-white md:text-sm rounded-full"
        >
          {translate("pages.forgotPassword.buttons.confirm")}
        </button>
      </div>
    </div>
  );
};

export default AuthForgotPasswordDialog;
