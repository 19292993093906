import {request} from "../../../utils/request";
import {RegisterParams} from "./type";

/**
 * 注册
 * @param data
 */
export const createRegister = async (data: RegisterParams) => {
    return request({
        url: '/api/auth/local/register',
        method: 'POST',
        data
    })
}
