import { AuthBindings } from "@refinedev/core";
import { createLogin } from "./lib/api/login";
import {
  clearLocalStorage,
  deleteAuthToken,
  getAuthToken,
  saveAuthToken,
} from "./utils/common";
import { createRegister } from "./lib/api/register";
import { queryMe } from "./lib/api/me";
import { toast } from "react-toastify";

export const authProvider: AuthBindings = {
  login: async ({ email, password }) => {
    // 重新登录清除token
    deleteAuthToken();
    try {
      // 登录api
      const res = await createLogin({ identifier: email, password });
      if (res.status === 200) {
        // 保存jwt
        saveAuthToken(res.data.jwt);
        return {
          success: true,
        };
      }
      return {
        success: false,
      };
    } catch (e: any) {
      return {
        success: false,
        error: {
          message: "Login failure",
          name:
            e.response.data.error.message || "Login failure,please try again",
        },
      };
    }
  },
  register: async (params) => {
    // 注册清除token
    deleteAuthToken();
    try {
      // 注册
      const res = await createRegister(params);
      if (res.status === 200) {
        // 保存jwt
        saveAuthToken(res.data.jwt);
        return {
          success: true,
        };
      }
      return { success: false };
    } catch (error: any) {
      return {
        success: false,
        error: {
          message: "Register failed",
          name: error.response.data.error.message || "Register failed",
        },
      };
    }
  },
  logout: async ({ redirectPath }) => {
    // 退出登录
    clearLocalStorage();
    return {
      success: true,
      redirectTo: redirectPath,
    };
  },
  onError: async (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      toast.error("The credentials are missing or invalid, please login again");
      return {
        logout: true,
        redirectTo: "/",
        error,
      };
    }
    return { error };
  },
  check: async () => {
    // 获取token
    const token = getAuthToken();

    if (token) {
      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      error: {
        message: "Please login again",
        name: "Check failed",
      },
      logout: true,
    };
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    // 获取token
    const token = getAuthToken();
    if (token) {
      const res = await queryMe();
      if (res.status === 200) {
        return res.data;
      }
    }

    return null;
  },
};
