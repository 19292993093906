import React, { memo, useMemo, useState } from "react";
import { ListItemButton, ListItemText, SxProps, Theme } from "@mui/material";
import { MMenu, MMenuItem } from "../index";
import { LOCALE_LIST } from "../../constant/comon";
import language from "/home/language@2x.png";

type LocaleButtonProps = {
  currentLanguage: string;
  setCurrentLanguage: React.Dispatch<React.SetStateAction<string>>;
  sx?: SxProps<Theme> | undefined;
};
export const PartLocale: React.FC<LocaleButtonProps> = memo(
  ({ currentLanguage, setCurrentLanguage, sx }) => {
    // 多语言切换
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const localeMenuOpen = Boolean(anchorEl);
    const id = localeMenuOpen ? "simple-popover" : undefined;
    // 选中的language
    const selectLanguage = useMemo(
      () => LOCALE_LIST.find((v) => v.value === currentLanguage)?.label,
      [currentLanguage]
    );
    // 打开多语言
    const handleLocaleMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
    };
    // 关闭
    const handleLocaleMenuClose = () => {
      setAnchorEl(null);
    };
    // 设置选中的语言
    const handleChangeLanguage = async (language: string) => {
      setCurrentLanguage(language);
      handleLocaleMenuClose();
    };
    return (
      <>
        <ListItemButton
          aria-describedby={id}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            px: 0.5,
            borderRadius: "4px",
            color: "var(--primary-2B363E)",
            ...sx,
          }}
          onClick={handleLocaleMenuClick}
        >
          <div className="px-2">
            <img src={language} alt="language" className="w-[16px]" />
          </div>
          <ListItemText
            className="text-sm lg:text-base"
            sx={{ flex: "none" }}
            primary={selectLanguage}
            primaryTypographyProps={{
              noWrap: true,
              fontSize: { xs: "14px", lg: "16px" },
            }}
          />
          <ListItemText
            sx={{
              justifyContent: "center",
              minWidth: "18px",
              transition: "margin-right 0.3s",
              marginLeft: "6px",
            }}
          >
            <span>{">"}</span>
          </ListItemText>
        </ListItemButton>
        <MMenu
          id={id}
          anchorEl={anchorEl}
          open={localeMenuOpen}
          onClose={handleLocaleMenuClose}
        >
          {LOCALE_LIST.map(({ value, label }, index) => (
            <MMenuItem
              divider={LOCALE_LIST.length - 1 !== index}
              key={value}
              selected={value === currentLanguage}
              onClick={() => handleChangeLanguage(value)}
            >
              {label}
            </MMenuItem>
          ))}
        </MMenu>
      </>
    );
  }
);
