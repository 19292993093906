import React from "react";
import Dialog from "@mui/material/Dialog";
import { AuthLogin } from "../auth-login";
import { useAuthDialog } from "../../../contexts/auth-dialog";
import { AuthRegister } from "../auth-register";
import { AuthFeedback } from "../auth-feedback";
import { AuthForgotPassword } from "../auth-forgot-password";
import AuthForgotPasswordDialog from "../auth-forgot-password-dialog";

export const AuthDialog = () => {
  const { authOpen, closeAuthDialog, authType } = useAuthDialog();
  const handleClose = () => {
    closeAuthDialog();
  };
  const authForm = () => {
    switch (authType) {
      case "LOGIN":
        return (
          <AuthLogin
            rememberMe={false}
            registerLink={false}
            forgotPasswordLink={false}
          />
        );
      case "REGISTER":
        return <AuthRegister loginLink={false} />;
      case "FEEDBACK":
        return <AuthFeedback />;
      case "FORGOT_PASSWORD":
        return <AuthForgotPassword />;
      case "FORGOT_PASSWORD_DIALOG":
        return <AuthForgotPasswordDialog />;
      default:
        return null;
    }
  };
  return (
    <Dialog onClose={handleClose} open={authOpen} maxWidth="xs" fullWidth>
      {authForm()}
    </Dialog>
  );
};
