import React, {
  createContext,
  useContext,
  Dispatch,
  PropsWithChildren,
  useReducer,
} from "react";
import { HomeInitialState } from "./type";

export type HomeContextProps = {
  state: HomeInitialState;
  dispatch: Dispatch<any>;
};

export type FieldNames<T> = {
  [K in keyof T]: T[K] extends string ? K : K;
}[keyof T];

type Action =
  | { type: "reset" }
  | { type?: "change"; field: FieldNames<HomeInitialState>; value: any };

const initialState: HomeInitialState = {
  loading: false,
  messageIsStreaming: false,
  conversations: [],
  currentMessage: [],
  messageError: false,
};

const ChatContext = createContext<HomeContextProps>({} as HomeContextProps);
const ChatContextProvider: React.FC<PropsWithChildren<any>> = ({
  children,
}) => {
  const reducer = (state: HomeInitialState, action: Action) => {
    if (!action.type) return { ...state, [action.field]: action.value };

    if (action.type === "reset") return initialState;

    throw new Error();
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <ChatContext.Provider value={{ state, dispatch }}>
      {children}
    </ChatContext.Provider>
  );
};

const useChatContext = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useChatContext must be used within a ChatContextProvider");
  } else {
    return context;
  }
};

export { ChatContextProvider, useChatContext };
