import { styled } from "@mui/material/styles";
import { MenuItem } from "@mui/material";

const py = "14px";
export const MMenuItem = styled(MenuItem)(({ theme }) => ({
  paddingTop: py,
  paddingBottom: py,
  ".MuiMenuItem-root": {
    py: "4px",
    backgroundColor: "red",
  },
}));
