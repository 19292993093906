import * as React from "react";
import {
  useCustomMutation,
  useTranslate,
  useApiUrl,
  useNotification,
} from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import { FormProvider } from "react-hook-form";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { layoutStyles, titleStyles } from "../styles";

import { CustomTitle } from "../../index";
import { useAuthDialog } from "../../../contexts/auth-dialog";

type AuthFeedbackForm = {
  message: string;
};

type AuthFeedbackProps = {};

export const AuthFeedback: React.FC<AuthFeedbackProps> = () => {
  const apiUrl = useApiUrl();
  const { closeAuthDialog } = useAuthDialog();
  const { open } = useNotification();
  const methods = useForm<AuthFeedbackForm>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;
  const { isLoading, mutate } = useCustomMutation();

  const translate = useTranslate();
  const createFeedback = handleSubmit((data) => {
    if (!!data.message.trim()) {
      mutate(
        {
          url: `${apiUrl}/api/user-feedbacks`,
          method: "post",
          values: data,
        },
        {
          onSuccess: () => {
            closeAuthDialog();
            open?.({
              type: "success",
              message: translate("pages.feedback.response.success.message"),
              description: translate(
                "pages.feedback.response.success.description"
              ),
            });
          },
          onError: () => {
            open?.({
              type: "error",
              message: translate("pages.feedback.response.error.message"),
              description: translate(
                "pages.feedback.response.error.description"
              ),
            });
          },
        }
      );
    }
  });
  const PageTitle = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "32px",
        fontSize: "20px",
      }}
    >
      <CustomTitle
        collapsed={false}
        wrapperStyles={{
          gap: "8px",
        }}
      />
    </div>
  );

  const Content = (
    <Card sx={{ width: "100%" }} elevation={0}>
      <CardContent sx={{ p: "32px", "&:last-child": { pb: "32px" } }}>
        <Typography
          component="h1"
          variant="h5"
          align="center"
          style={titleStyles}
          color="primary"
          fontWeight={700}
        >
          {translate("pages.feedback.title", "You feedback matters!")}
        </Typography>
        <Box component="form" onSubmit={createFeedback}>
          <TextField
            {...register("message", {
              required: true,
            })}
            id="message"
            margin="normal"
            fullWidth
            error={!!errors.message}
            name="message"
            type="text"
            multiline
            rows={3}
            maxRows={3}
            autoComplete="message"
            sx={{
              mt: 0,
            }}
          />
          <Button
            type="submit"
            fullWidth
            disableElevation
            variant="contained"
            disabled={isLoading}
            sx={{ mt: "24px" }}
          >
            {translate("pages.feedback.buttons.submit", "Send")}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <FormProvider {...methods}>
      <Box component="div" style={layoutStyles}>
        <Container
          component="main"
          maxWidth={false}
          disableGutters
          sx={{
            width: "100%",
            pt: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <>
              {PageTitle}
              {Content}
            </>
          </Box>
        </Container>
      </Box>
    </FormProvider>
  );
};
