import { CSSProperties } from "react";

export const resetPasswordLayoutStyles: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
};

export const layoutStyles: CSSProperties = {};

export const titleStyles: CSSProperties = {
  textAlign: "center",
  fontSize: "24px",
  marginBottom: "24px",
  overflowWrap: "break-word",
  hyphens: "manual",
  textOverflow: "unset",
  whiteSpace: "pre-wrap",
};
