import * as React from "react";
import {
  LoginPageProps,
  LoginFormTypes,
  useActiveAuthProvider,
  useNotification,
} from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import { FormProvider } from "react-hook-form";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from "@mui/material/Divider";
import MuiLink from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import type { BoxProps } from "@mui/material/Box";
import type { CardContentProps } from "@mui/material/CardContent";
import {
  BaseRecord,
  HttpError,
  useLogin,
  useTranslate,
  useRouterContext,
  useRouterType,
  useLink,
} from "@refinedev/core";
import { layoutStyles, titleStyles } from "../styles";

import { CustomTitle } from "../../index";
import { FormPropsType } from "../type";
import { useAuthDialog } from "../../../contexts/auth-dialog";
// import { useOpenReplay } from "../../../contexts/openreplay";
import {
  getOpenReplayNannoId,
  saveOpenReplayNannoId,
} from "../../../utils/common";

type LoginProps = LoginPageProps<BoxProps, CardContentProps, FormPropsType>;

export const AuthLogin: React.FC<LoginProps> = (props) => {
  const {
    providers,
    registerLink,
    rememberMe,
    contentProps,
    wrapperProps,
    renderContent,
    formProps,
    title,
  } = props;
  // const { tracker } = useOpenReplay();
  const { onSubmit, ...useFormProps } = formProps || {};
  const methods = useForm<BaseRecord, HttpError, LoginFormTypes>({
    ...useFormProps,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const authProvider = useActiveAuthProvider();
  const { mutate: login, isLoading } = useLogin<LoginFormTypes>({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  });
  const { open } = useNotification();
  const translate = useTranslate();
  const { closeAuthDialog, openAuthDialog } = useAuthDialog();
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();
  const openReplayNannoId = getOpenReplayNannoId();

  const ActiveLink = routerType === "legacy" ? LegacyLink : Link;
  // 登录
  const handleOnSubmit = handleSubmit((data) => {
    if (onSubmit) {
      return onSubmit(data);
    }
    return login(data, {
      onSuccess: (successData) => {
        if (successData.success) {
          saveOpenReplayNannoId(`${openReplayNannoId}_LOGGED_${data.email}`);
          // tracker.setUserID(`${openReplayNannoId}_LOGGED_${data.email}`);
          open?.({
            type: "success",
            message: translate(
              "pages.login.response.success.message",
              "Login successful."
            ),
          });
          closeAuthDialog();
        }
      },
    });
  });
  const PageTitle =
    title === false ? null : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          // marginBottom: "32px",
          fontSize: "20px",
        }}
      >
        {title ?? (
          <CustomTitle
            collapsed={false}
            wrapperStyles={{
              gap: "8px",
            }}
          />
        )}
      </div>
    );

  const renderProviders = () => {
    if (providers && providers.length > 0) {
      return (
        <>
          <Stack spacing={1}>
            {providers.map((provider: any) => {
              return (
                <Button
                  key={provider.name}
                  variant="outlined"
                  fullWidth
                  sx={{
                    color: "primary.light",
                    borderColor: "primary.light",
                    textTransform: "none",
                  }}
                  onClick={() => login({ providerName: provider.name })}
                  startIcon={provider.icon}
                >
                  {provider.label}
                </Button>
              );
            })}
          </Stack>
          <Divider
            sx={{
              fontSize: "12px",
              marginY: "16px",
            }}
          >
            {translate("pages.login.divider", "or")}
          </Divider>
        </>
      );
    }
    return null;
  };

  const Content = (
    <Card {...(contentProps ?? {})} sx={{ width: "100%" }} elevation={0}>
      <CardContent sx={{ p: "32px", "&:last-child": { pb: "44px" } }}>
        <Typography
          component="h1"
          variant="h5"
          align="center"
          style={titleStyles}
          color="primary"
          fontWeight={700}
        >
          {translate("pages.login.title", "Sign in to your account")}
        </Typography>
        <Box component="form" onSubmit={handleOnSubmit}>
          {renderProviders()}
          <TextField
            {...register("email", {
              required: true,
            })}
            id="email"
            margin="normal"
            fullWidth
            label={translate("pages.login.fields.email", "Email")}
            error={!!errors.email}
            name="email"
            type="email"
            autoComplete="email"
            sx={{
              mt: 0,
            }}
          />
          <TextField
            {...register("password", {
              required: true,
              minLength: {
                value: 6,
                message: "The password cannot be less than 6 characters",
              },
            })}
            id="password"
            margin="normal"
            fullWidth
            name="password"
            label={translate("pages.login.fields.password", "Password")}
            helperText={errors?.password?.message}
            error={!!errors.password}
            type="password"
            placeholder="●●●●●●●●"
            autoComplete="current-password"
            sx={{
              mb: 0,
            }}
          />

          <Box
            component="div"
            sx={{
              mt: "24px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {rememberMe ?? (
              <FormControlLabel
                sx={{
                  span: {
                    fontSize: "14px",
                    color: "text.secondary",
                  },
                }}
                color="secondary"
                control={
                  <Checkbox
                    size="small"
                    id="remember"
                    {...register("remember")}
                  />
                }
                label={translate(
                  "pages.login.buttons.rememberMe",
                  "Remember me"
                )}
              />
            )}
            <Typography
              onClick={() => openAuthDialog("FORGOT_PASSWORD")}
              variant="body2"
              color="primary"
              fontSize="12px"
              component={ActiveLink}
              underline="none"
            >
              {translate(
                "pages.login.buttons.forgotPassword",
                "Forgot password?"
              )}
            </Typography>
          </Box>
          <Stack spacing={1} sx={{ mt: "24px" }}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
              disabled={isLoading}
            >
              {translate("pages.login.signin", "Sign in")}
            </Button>
            <Button
              type="submit"
              fullWidth
              disabled={isLoading}
              onClick={() => openAuthDialog("REGISTER")}
            >
              {translate("pages.login.signup", "Sign up")}
            </Button>
          </Stack>

          {registerLink ?? (
            <Box
              sx={{
                mt: "24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                textAlign="center"
                variant="body2"
                component="span"
                fontSize="12px"
              >
                {translate(
                  "pages.login.buttons.noAccount",
                  "Don’t have an account?"
                )}
              </Typography>
              <MuiLink
                ml="4px"
                fontSize="12px"
                variant="body2"
                color="primary"
                component={ActiveLink}
                underline="none"
                to="/register"
                fontWeight="bold"
              >
                {translate("pages.login.signup", "Sign up")}
              </MuiLink>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <FormProvider {...methods}>
      <Box component="div" style={layoutStyles} {...(wrapperProps ?? {})}>
        <Container
          component="main"
          maxWidth={false}
          disableGutters
          sx={{
            idth: "100%",
            pt: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            // height: "100vh",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {renderContent ? (
              renderContent(Content, PageTitle)
            ) : (
              <>
                {PageTitle}
                {Content}
              </>
            )}
          </Box>
        </Container>
      </Box>
    </FormProvider>
  );
};
