import React from "react";
import { useNavigate } from "react-router-dom";
import headerBack from "/company/header_back@2x.png";

export const GoBackButton = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <div
    // className="hidden md:block"
    >
      <button
        onClick={handleGoBack}
        className="ml-3 md:ml-4 px-3 py-1 bg-[var(--primary-ECF5FF)] flex justify-center items-center gap-x-1 rounded-full"
      >
        <span className="w-[14px] h-[12.5px]">
          <img src={headerBack} alt="headerBack" />
        </span>
        <span className="font-normal text-sm">return</span>
      </button>
    </div>
  );
};
