import React from "react";
import { useRouterContext, useLink, useRouterType } from "@refinedev/core";
import { RefineLayoutThemedTitleProps } from "@refinedev/mui";
import MuiLink from "@mui/material/Link";
import logo from "/logo@2x.png";

const defaultText = "OBOdoc";
export const CustomTitle: React.FC<RefineLayoutThemedTitleProps> = (props) => {
  const { collapsed, wrapperStyles, text = defaultText } = props;
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();

  const ActiveLink = routerType === "legacy" ? LegacyLink : Link;

  return (
    <MuiLink
      to="/"
      component={ActiveLink}
      underline="none"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "12px",
        ...wrapperStyles,
      }}
    >
      <div className="flex-none">
        <div>
          <img src={logo} alt="logo" className="w-[28px] md:w-[36px]" />
        </div>
      </div>
      {!collapsed && (
        <h6 className="title font-extrabold overflow-hidden hidden md:block">
          {text}
        </h6>
      )}
    </MuiLink>
  );
};
