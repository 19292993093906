import React, {createContext, PropsWithChildren, useContext, useState} from 'react';
import {AuthType} from '../../types'

type CreateContextType = {
    authOpen: boolean;
    openAuthDialog: (type: AuthType) => void;
    closeAuthDialog: () => void;
    authType: AuthType
}

const AuthDialogContext = createContext<CreateContextType>({} as CreateContextType)
const AuthDialogProvider: React.FC<PropsWithChildren<any>> = ({children}) => {
    // 登录注册弹窗状态
    const [authOpen, setAuthOpen] = useState<boolean>(false)
    // 弹窗显示内容
    const [authType, setAuthType] = useState<AuthType>('LOGIN')
    // 显示弹窗
    const openAuthDialog = (type: AuthType) => {
        setAuthOpen(true)
        setAuthType(type)
    }
    // 关闭弹窗
    const closeAuthDialog = () => {
        setAuthOpen(false)
    }
    const value = {authOpen, openAuthDialog, closeAuthDialog, authType}
    return (
        <AuthDialogContext.Provider value={value}>
            {children}
        </AuthDialogContext.Provider>
    );
};

const useAuthDialog = () => useContext(AuthDialogContext)
export {AuthDialogProvider, AuthDialogContext, useAuthDialog};
