import type { CSSProperties, ReactNode } from "react";

export type BasicComponent = {
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
  id?: string;
};

export const ComponentDefaults = {
  className: "",
  style: {},
};
