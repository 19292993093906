import React, { useCallback, useMemo, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";
import {
  Pagination,
  PaginationItem,
  Popover,
  useMediaQuery,
} from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { styled } from "@mui/material/styles";
import { useCustom, useTranslate } from "@refinedev/core";
import { Link } from "react-router-dom";
import { DATE_FORMAT } from "../../constant/comon";
import { generateDateRange, numeralFormat } from "../../utils/common";
import { RootCalendarsEarning, RootCalendarsEarningData } from "../../types";
import calendarDateIcon from "/home/calendar_date_icon@2x.png";

export const Calendar = () => {
  const matches = useMediaQuery("(min-width:768px)");
  const translate = useTranslate();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  // 日历时间
  const [dateCalendarValue, setDateCalendarValue] = useState<Dayjs | null>(
    dayjs()
  );
  // 格式化数据请求日期格式
  const queryDate = dayjs(dateCalendarValue).format(DATE_FORMAT);
  // 日历环日历
  const [dateRangeValue, setDateRangeValue] = useState<Dayjs>(dayjs());
  // 日历环数据
  const dateRange = generateDateRange(dateRangeValue, matches ? 7 : 3);
  // 弹唱状态
  const open = Boolean(anchorEl);
  // 弹窗id
  const id = open ? "calendar-popover" : undefined;
  // 分页，每页条数
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  // 获取财报日历数据
  const { data: calendarsEarningData } = useCustom<RootCalendarsEarning>({
    url: `/calendars/earnings`,
    method: "get",
    dataProviderName: "company",
    config: {
      query: {
        date: queryDate,
        offset: pagination.pageIndex * pagination.pageSize,
        limit: pagination.pageSize,
      },
    },
  });
  // 当前页数据
  const calendarsEarnings = calendarsEarningData?.data?.data || [];
  // 总数据条数
  const calendarsEarningsTotal = calendarsEarningData?.data?.meta.total || 0;
  // 总页数
  const calendarsEarningsPage = Math.ceil(
    calendarsEarningsTotal / pagination.pageSize
  );

  // 点击弹窗日历日期
  const handleChangeDate = (newValue: Dayjs) => {
    setDateCalendarValue(newValue);
    setDateRangeValue(newValue);
    table.resetPageIndex();
    handleCloseCalendar();
  };

  // 点击日历环箭头，增加或者减少日期
  const handleSubtractOrAdd = (type: "SUBTRACT" | "ADD") => () => {
    switch (type) {
      case "ADD":
        return setDateRangeValue(dayjs(dateRangeValue).add(1, "day"));
      case "SUBTRACT":
        return setDateRangeValue(dayjs(dateRangeValue).subtract(1, "day"));
      default:
        return null;
    }
  };

  // 选中日历环日期
  const handleDateItem = (date: string) => () => {
    table.resetPageIndex();
    setDateCalendarValue(dayjs(date));
  };

  // 打开日历
  const handleOpenCalendar = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // 关闭日历
  const handleCloseCalendar = () => {
    setAnchorEl(null);
  };

  // 表头
  const columns = useMemo<ColumnDef<RootCalendarsEarningData>[]>(
    () => [
      {
        header: "Time",
        id: "time",
        accessorKey: "time",
        cell: (props) => (
          <span className="min-w-6 h-6 uppercase">
            {props.row.original.time}
          </span>
        ),
      },
      {
        header: "Symbol",
        id: "symbol",
        accessorKey: "symbol",
        cell: (props) => (
          <Link to={`/company?cik=${props.row.original.cik}`}>
            {props.row.original.symbol}
          </Link>
        ),
      },
      {
        header: "Company Name",
        id: "name",
        accessorKey: "name",
        cell: (props) => (
          <Link
            to={`/company?cik=${props.row.original.cik}`}
            className="w-[400px]"
          >
            {props.row.original.name}
          </Link>
        ),
      },
      {
        header: "Fiscal Date Ending",
        id: "fiscalDateEnding",
        accessorKey: "fiscalDateEnding",
      },
      {
        header: "Eps",
        id: "eps",
        accessorKey: "eps",
        accessorFn: (row) => (row.eps ? row.eps : "N/A"),
      },
      {
        header: "Eps Estimated",
        id: "epsEstimated",
        accessorKey: "epsEstimated",
        accessorFn: (row) => (row.epsEstimated ? row.epsEstimated : "N/A"),
      },
      {
        header: "Revenue",
        id: "revenue",
        accessorKey: "revenue",
        accessorFn: (row) => (row.revenue ? numeralFormat(row.revenue) : "N/A"),
      },
      {
        header: "Revenue Estimated",
        id: "revenueEstimated",
        accessorFn: (row) =>
          row.revenueEstimated ? numeralFormat(row.revenueEstimated) : "N/A",
      },
    ],
    []
  );
  // 表格
  const table = useReactTable({
    data: calendarsEarnings,
    columns,
    pageCount: calendarsEarningsTotal,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    //no need to pass pageCount or rowCount with client-side pagination as it is calculated automatically
    state: {
      pagination,
    },
    manualPagination: true,
  });

  // 改变分页
  const handleChange = (e: React.ChangeEvent<unknown>, value: number) => {
    table.setPageIndex(value - 1);
  };
  // 箭头图标
  const DataIcon = useCallback(
    () => <img src={calendarDateIcon} alt="calendarDateIcon" />,
    []
  );
  return (
    <div className="p-4 bg-white shadow-[0px_6px_25px_0px_rgba(200,200,200,0.2)] rounded-lg overflow-hidden">
      <div className="py-6 px-6 md:px-10 flex justify-between items-center border-b border-[var(--primary-666666)] text-[var(--primary-2B363E)]">
        <h5 className="text-sm md:text-base font-medium">
          {translate("calendar.earningsCalendar", "Earnings Calendar")}
        </h5>
        <button
          className="text-xs md:text-base"
          aria-describedby={id}
          onClick={handleOpenCalendar}
        >
          {translate("calendar.selectDate", "SELECT DATE")}
        </button>
      </div>
      <div className="py-2 px-6 md:px-10 flex justify-between items-center gap-x-6">
        <button
          className="w-[40px] h-[35px]"
          onClick={handleSubtractOrAdd("SUBTRACT")}
        >
          <DataIcon />
        </button>
        {dateRange.map((d, index) => (
          <button
            key={index}
            onClick={handleDateItem(d.date)}
            className={`${
              d.date === dayjs(dateCalendarValue).format(DATE_FORMAT)
                ? "text-[var(--primary)] font-medium"
                : "text-[var(--primary-2B363E)]"
            } flex flex-nowrap text-xs`}
          >
            <span className="hidden md:block">{d.dayOfWeek},</span>{" "}
            {d.dataOfMonth}{" "}
          </button>
        ))}
        <button
          className="w-[40px] h-[35px] rotate-180"
          onClick={handleSubtractOrAdd("ADD")}
        >
          <DataIcon />
        </button>
      </div>
      <div className="relative after:content-[''] after:absolute after:w-10 after:top-0 after:right-0 after:bottom-0 after:bg-gradient-to-l after:from-white after:from-20% after:z-10 md:after:hidden">
        <div className="overflow-auto">
          <table className="w-full overflow-auto border-spacing-0 border-collapse">
            <thead className="border-t-2 border-b border-[var(--primary-666666)]">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      className="p-4 first:pl-6 md:first:pl-10 last:pr-6 md:last:pr-10 text-[10px] md:text-sm text-left font-medium whitespace-nowrap text-[var(--primary-2B363E)]"
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className="hover:bg-slate-100">
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="p-4 first:pl-6 md:first:pl-10 last:pr-6 md:last:pr-10 text-left text-[10px] md:text-sm text-[var(--primary-2B363E)] font-normal md:whitespace-nowrap"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {calendarsEarningsPage > 0 && (
        <div className="pt-6 flex justify-center">
          <CPagination
            count={calendarsEarningsPage}
            page={pagination.pageIndex + 1}
            onChange={handleChange}
            color="primary"
            shape="rounded"
            renderItem={(item) => {
              return <PaginationItem {...item} />;
            }}
          />
        </div>
      )}

      <div className="pt-10 pl-6 md:pl-10">
        <p className="flex gap-x-3 text-[10px] md:text-sm text-[var(--primary-9699A6)]">
          <span>Key:</span>
          <span>{translate("calendar.bmo", "BMO Before Market Open")}</span>
          <span className="pl-2">
            {translate("calendar.amc", "AMC After Market Close")}
          </span>
        </p>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseCalendar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar value={dateCalendarValue} onChange={handleChangeDate} />
        </LocalizationProvider>
      </Popover>
    </div>
  );
};

const CPagination = styled(Pagination)(({ theme }) => ({
  // width: 300,
  color: theme.palette.text.secondary,
  "& .MuiPaginationItem-root": {
    color: theme.palette.primary.main,
    ".Mui-selected": {
      color: "#FFF",
      background: theme.palette.primary.main,
    },
  },
}));
