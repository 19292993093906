import React, { memo } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "./../../styles/markdown.css";

type MarkdownContentProps = {
  content: string;
};

export const MarkdownContent: React.FC<MarkdownContentProps> = memo((props) => {
  const { content } = props;
  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      children={content}
      className="prose-sm prose-headings:font-medium max-w-none markdown overflow-x-scroll text-[var(--primary-2B363E)]"
    />
  );
});
