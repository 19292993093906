import { Toolbar, Stack, AppBar } from "@mui/material";
import { useTitle } from "@refinedev/core";
import { RefineThemedLayoutV2HeaderProps } from "@refinedev/mui";
import React from "react";
import { User } from "../user";
import { CustomTitle } from "../title";
import { GoBackButton } from "../goBack";

type HeaderProps = {
  locale?: React.ReactNode;
} & RefineThemedLayoutV2HeaderProps;

export const Header: React.FC<HeaderProps> = ({ sticky = true, locale }) => {
  const TitleFromContext = useTitle();
  const RenderToTitle = TitleFromContext ?? CustomTitle;
  return (
    <AppBar
      color="transparent"
      position={sticky ? "sticky" : "fixed"}
      sx={{
        boxShadow: "none",
      }}
    >
      <Toolbar className="bg-[var(--primary-CEE6FF)] backdrop-blur">
        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          gap="20px"
        >
          <div className="xl:min-w-[380px] xl:max-w-[380px] flex justify-start items-center">
            <RenderToTitle collapsed={false} />
            <GoBackButton />
          </div>

          <Stack
            flex="none"
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            gap="12px"
          >
            {locale && <div className="flex">{locale}</div>}
            <User />
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
