import { request } from "../../../utils/request";

/**
 * 查询用户信息
 */
export const queryMe = async () => {
  return request({
    url: "/api/account/me",
    method: "GET",
  });
};
