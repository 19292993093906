import React, { useMemo } from "react";
import classNames from "classnames";
import remarkGfm from "remark-gfm";
import Markdown from "react-markdown";
import RehypeHighlight from "rehype-highlight";
import { Message } from "../../contexts/chat-context/type";
import chatLogoDoc from "/company/chat_logo_doc@2x.png";
import chatLogoMe from "/company/chat_logo_me@2x.png";
import "./../../styles/highlight.css";
import "./../../styles/markdown.css";

type CMessageProps = {
  message: Message;
  _index: number;
  icon?: string;
  messageMarginL?: string;
  messageMarginR?: string;
};

export const CMessage: React.FC<CMessageProps> = React.memo((props) => {
  const {
    message,
    icon = "w-12 h-12",
    messageMarginL = "ml-1 xl:ml-[30%]",
    messageMarginR = "mr-1 xl:mr-[30%]",
  } = props;

  function escapeDollarNumber(text: string) {
    let escapedText = "";

    for (let i = 0; i < text.length; i += 1) {
      let char = text[i];
      const nextChar = text[i + 1] || " ";
      if (char === "$" && nextChar >= "0" && nextChar <= "9") {
        char = "\\$";
      }
      escapedText += char;
    }
    return escapedText;
  }

  function escapeBrackets(text: string) {
    const pattern =
      /(```[\s\S]*?```|`.*?`)|\\\[([\s\S]*?[^\\])\\\]|\\\((.*?)\\\)/g;
    return text.replace(
      pattern,
      (match, codeBlock, squareBracket, roundBracket) => {
        if (codeBlock) {
          return codeBlock;
        } else if (squareBracket) {
          return `$$${squareBracket}$$`;
        } else if (roundBracket) {
          return `$${roundBracket}$`;
        }
        return match;
      }
    );
  }

  const escapedContent = useMemo(() => {
    return escapeBrackets(escapeDollarNumber(message.content));
  }, [message.content]);

  return (
    <div
      className={classNames(
        `flex justify-start gap-4 ${
          message.role === "user" ? `${messageMarginL} flex-row-reverse` : ""
        } ${message.role === "system" ? `${messageMarginR}` : ""}`
      )}
    >
      <div
        className={classNames(icon, "justify-center items-center flex-none")}
      >
        {message.role === "user" ? (
          <img src={chatLogoMe} alt="chatLogoDoc" />
        ) : (
          <img src={chatLogoDoc} alt="chatLogoDoc" />
        )}
      </div>
      <Markdown
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[
          [
            RehypeHighlight,
            {
              detect: false,
              ignoreMissing: true,
            },
          ],
        ]}
        components={{
          // @ts-ignore
          pre: PreCode,
          p: (pProps) => <p {...pProps} dir="auto" />,
          a: (aProps) => {
            const href = aProps.href || "";
            const isInternal = /^\/#/i.test(href);
            const target = isInternal ? "_self" : aProps.target ?? "_blank";
            return <a {...aProps} target={target} />;
          },
        }}
        className={`${
          message.role === "user"
            ? "bg-[var(--primary-F3F4F6)]"
            : "bg-[#EFF4FB]"
        } prose max-w-none markdown p-6 rounded-lg flex-initial break-words text-sm text-[var(--primary-2B363E)] overflow-x-scroll`}
      >
        {escapedContent}
      </Markdown>
    </div>
  );
});

const PreCode = (props: { children: any }) => {
  return <pre>{props.children}</pre>;
};
