import { RefineThemes } from "@refinedev/mui";
import { createTheme } from "@mui/material/styles";

export const customTheme = createTheme({
  ...RefineThemes.Blue,
  palette: {
    ...RefineThemes.Blue.palette,
    primary: {
      main: "#4EA6FF",
      contrastText: "#fff",
    },
    secondary: {
      main: "#394FF6",
    },
    divider: "#F4F6F9",
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "contained" && {
            // Some CSS
            color: "#fff",
            borderRadius: "100px",
          }),
          textTransform: "none",
        }),
      },
    },
  },
  typography: {
    fontFamily: [
      "PingFangSC",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});
